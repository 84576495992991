import React from 'react';
import { 
  Paper,
  Divider
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { teal, pink } from '@material-ui/core/colors';
import { Done } from '@material-ui/icons';
import './App.scss';

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: teal[100],
      },
      secondary: pink,
    },
  });

  return (
    <div className="app">
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <Paper
          className="paper plain"
          elevation={0}
          square
        >
          <ul>
            <li>
              <p>Ads free</p>

              <Done 
                color="secondary" 
                className="icon"
              />
            </li>

            <Divider />

            <li>
              <p>Safe content</p>

              <Done 
                color="secondary" 
                className="icon"
              />
            </li>

            <Divider />

            <li>
              <p>Won't sell & won't share your data</p>

              <Done 
                color="secondary" 
                className="icon"
              />
            </li>
          </ul>
        </Paper>

        <Paper
          className="paper colored"
          elevation={1}
          style={{
            backgroundColor: theme.palette.primary.main
          }}
          square
        >
          <h1>A Good Social Media</h1>
          
          <h3>Coming soon!</h3>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default App;
